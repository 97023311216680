import React from "react";

import Layout from "../components/common/layout/layout";
import SEO from "../components/common/layout/seo";
// import Navigation from "../components/common/navigation/navigation";
import Navigation from "../components/common/navigation/navigation-new";
import Header from "../components/sections/about-us/header";
import WhoAndWhy from "../components/sections/about-us/who-and-why";
// import OurValues from "../components/sections/about-us/our-values";
// import JobBanner from "../components/sections/about-us/banner";
import Footer from "../components/sections/footer/index-new";

const AboutUs = () => {
  return (
    <Layout>
      <SEO
        title="About OPay"
        description="Delivering on the promise of financial inclusion in Africa today to safely connect people with the places, opportunities, and experiences that they truly care about."
      />
      <Navigation background="white" />
      <Header />
      <WhoAndWhy />
      {/* <OurValues /> */}
      {/* <JobBanner /> */}
      <Footer />
    </Layout>
  );
};

export default AboutUs;
